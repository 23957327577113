import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Properties from '../Components-ar/Properties/properties';
import PropertyDetails from '../Components-ar/Properties/property-details';
import LoginAr from '../Components-ar/loginAr';
import AuthGuardAr from '../Services/AuthGuard-Ar';
import PublicPagesGuardAr from '../Services/PublicPagesGuardAr';
import LayoutAr from '../Components-ar/Layout/LayoutAr';
import Settings from '../Components-ar/Settings/settings';
import OCR from '../Components-ar/Understand/Document/Ocr';
import SentimentAnalysisText from '../Components-ar/Understand/Text/sentiment-analysis-text';
import FeedbackAnalysisText from '../Components-ar/Understand/Text/feedback-analysis-text';
import ToneText from '../Components-ar/Understand/Text/tone-text';
import DetectConvesrationPredefinedTopics from '../Components-ar/Understand/Text/detect-convesration-predefined-topics';
import DetectConvesrationAutoIdentif from '../Components-ar/Understand/Text/detect-convesration-auto-identification';
import ImageBrightnessScore from '../Components-ar/Understand/ImgCompliance/image-brightness-score';
import ImageQualityScore from '../Components-ar/Understand/ImgCompliance/image-quality-score';
import DetectConvesrationTopics from '../Components-ar/Understand/Audio/detect-convesration-topics';
import AssignPredefinedTopics from '../Components-ar/Understand/Audio/assign-predefined-topics';
import SentimentAnalysis from '../Components-ar/Understand/Audio/sentiment-analysis';
import FeedbackAnalysis from '../Components-ar/Understand/Audio/feedback-analysis';
import Tone from '../Components-ar/Understand/Audio/tone';
import VoiceFeatures from '../Components-ar/Understand/Audio/voice-features';
import HateSpeechRacial from '../Components-ar/Understand/Audio/hate-speech-racial';
import TranscribeAudio from '../Components-ar/Understand/Audio/transcribe-audio';
import ResidentialType from '../Components-ar/Understand/ImgContent/residential-type';
import CommercialType from '../Components-ar/Understand/ImgContent/commercial-type';
import Indoor from '../Components-ar/Understand/ImgContent/indoor';
import ResidProperty from '../Components-ar/Understand/ImgContent/resid-property';
import PropertyView from '../Components-ar/Understand/ImgContent/property-view';
import BuildingFacility from '../Components-ar/Understand/ImgContent/building-facility';
import WatermarkPresent from '../Components-ar/Understand/ImgContent/watermark-present';
import NSFWContent from '../Components-ar/Understand/ImgCompliance/NSFW-content';
import PeoplePresent from '../Components-ar/Understand/ImgCompliance/people-present';
import PhoneNumber from '../Components-ar/Understand/ImgCompliance/phone-number';
import ImageRotated from '../Components-ar/Understand/ImgCompliance/image-rotated';
import ImageBoxed from '../Components-ar/Understand/ImgCompliance/image-boxed';
import DocumentType from '../Components-ar/Understand/Document/document-type';
import EmiratesContent from '../Components-ar/Understand/Document/emirates- content';
import PassportContent from '../Components-ar/Understand/Document/passport-content';
import TitleDeedContent from '../Components-ar/Understand/Document/title-deed-content';
import DocumentContent from '../Components-ar/Understand/Document/document-content';
import PersonalyIdentifiable from '../Components-ar/Understand/Text/personaly-identifiable';
import RacialSpeech from '../Components-ar/Understand/Text/racial-speech';
import NSFWContentText from '../Components-ar/Understand/Text/NSFW-content-text';
import UnfurnishedFurnished from '../Components-ar/Understand/ImgContent/unfurnished-furnished';
import Profile from '../Components-ar/Settings/profile';
import GenerateMain from '../Components-ar/Generate/generate-main';
import PropertyTitle from '../Components-ar/Generate/text/property-title';
import PropertyDescription from '../Components-ar/Generate/text/property-description';
import CoraGPT from '../Components-ar/Generate/text/coraGPT';
import RoomStaging from '../Components-ar/Generate/image/room-staging.tsx';
import RoomImage from '../Components-ar/Generate/image/room-image';
import PhotoCollage from '../Components-ar/Generate/video/photo-collage';
import AgentProfile from '../Components-ar/Generate/video/Agent Profile';
import SocialMedia from '../Components-ar/Generate/video/social-media';
import Template from '../Components-ar/Generate/video/template';
import RoomModeling from '../Components-ar/Generate/image/roomodeling';
import ItemsStatus from '../Components-ar/Moderate/items-status';
import ItemDetails from '../Components-ar/Moderate/item-details';
import AgentPlusMain from '../Components-ar/AgentPlus/agent-plus-main';
import ListingBooster from '../Components-ar/Enhance/listing-booster/listing-booster';
import ImageSuperResolution from '../Components-ar/Enhance/image/image-super-resolution';
import RemoveWatermak from '../Components-ar/Enhance/image/remove-watermark';
import EnhanceMain from '../Components-ar/Enhance/enhance-main';
import RemoveImageNoise from '../Components-ar/Enhance/image/remove-image-noise';
import ImproveContrast from '../Components-ar/Enhance/image/improve-image-contrast';
import BlurrCar from '../Components-ar/Enhance/image/blurr-car-plates';
import BlurrPeople from '../Components-ar/Enhance/image/blurr-people-faces';
import UnboxImage from '../Components-ar/Enhance/image/unbox-image';
import RemoveReplacePII from '../Components-ar/Enhance/text/remove-replace-PII';
import Home from '../Components-ar/home';
import DelightMain from '../Components-ar/Delight/delight-main';
import Invitation from '../Components-ar/Users/invitation';
import GenerateLeaseAgreement from '../Components-ar/Generate/GenerateLeaseAgreement/generate-lease-agreement';
import ResizeImage from '../Components-ar/Enhance/image/resize-image';
import ImgContentMain from '../Components-ar/Understand/ImgContent/img-content-main';
import ImgComplianceMain from '../Components-ar/Understand/ImgCompliance/image-compliance-main';
import TextMain from '../Components-ar/Understand/Text/text-main';
import DocumentMain from '../Components-ar/Understand/Document/DocumentMain';
import AudioMain from '../Components-ar/Understand/Audio/audio-main';
import Overview from '../Components-ar/Moderate/overview';
import Items from '../Components-ar/Moderate/items';
import Rules from '../Components-ar/Moderate/rules';
import Manuals from '../Components-ar/Moderate/manuals';
import DuplicateSettings from '../Components-ar/Moderate/duplicate-settings';
import AIGeneration from '../Components-ar/Generate/listing/AIGeneration';
import RemoveDuplicate from '../Components-ar/Enhance/image/remove-duplicate';
import AddWatermak from '../Components-ar/Enhance/image/add-watermark';
import GenerateQRCode from '../Components-ar/Generate/image/generate-QR-code';
import CompressImage from '../Components-ar/Enhance/image/compress-image';
import ImageTagger from '../Components-ar/Understand/ImgContent/image-tagger';
import ImageAIGenerated from '../Components-ar/Understand/ImgCompliance/image-ai-generated';
import ImageComputerGenerated from '../Components-ar/Understand/ImgCompliance/image-computer-generated';
import RemoveWatermakUniversal from '../Components-ar/Enhance/image/remove-watermark-universal';
import AddQRCodeImage from '../Components-ar/Enhance/image/add-QR-code-image';
import AddQRCodeVideo from '../Components-ar/Enhance/image/add-QR-code-video';
import AddQRCodeListing from '../Components-ar/Enhance/image/add-QR-code-listing';
import LowLightEnhancement from '../Components-ar/Enhance/image/low-light-enhancement';
import ImageResizeToWebp from '../Components-ar/Enhance/image/image-resize-to-webp';
import ListingVideo from '../Components-ar/Generate/video/listing-video';
import FloorPlan3D from '../Components-ar/Generate/listing/floor-plan-3D';
import RemoveObjects from '../Components-ar/Enhance/image/remove-objects';
import AgentProfileNew from '../Components-ar/Generate/video/agent-profile-new';
import RemoveObjectsManual from '../Components-ar/Enhance/image/remove-objects-manual';
import SkyReplacementBlueSky from '../Components-ar/Enhance/image/sky-replacement-blue-sky';
import SkyReplacementDuskShot from '../Components-ar/Enhance/image/sky-replacement-dusk-shot';
import StyleTransfer from '../Components-ar/Generate/image/generate-style-transfer';


const PublicRouterAr: React.FunctionComponent<{}> = (props) => {

    const storedPlatforms: any = JSON.parse(localStorage.getItem('get-platforms') || '[]');

    return (

        <Routes>
            <Route path="/ar/acceptinvit/:id" element={<Invitation />} />
            <Route path="/ar/login" element={<PublicPagesGuardAr><LoginAr /></PublicPagesGuardAr>} />
            <Route path="ar" element={<PublicPagesGuardAr><LoginAr /></PublicPagesGuardAr>} />
            <Route path="ar/" element={<AuthGuardAr><LayoutAr /></AuthGuardAr>} >
                {storedPlatforms ?
                    storedPlatforms.map((platform: any, index: any) => {
                        if (platform.home) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/ar/home" element={platform.home ? <AuthGuardAr><Home /></AuthGuardAr> : <Navigate to="/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.enhance) {
                            return (
                                <React.Fragment key={index}> 
                                    <Route path="/ar/enhance" element={platform.enhance ? <AuthGuardAr><EnhanceMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/image-super-resolution" element={platform.enhance.includes('all') || platform.enhance.includes('Image Super Resolution') ? <AuthGuardAr><ImageSuperResolution /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/remove-image-noise" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Image Noise') ? <AuthGuardAr><RemoveImageNoise /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/sky-replacement-blue-sky" element={platform.enhance.includes('all') || platform.enhance.includes("Sky Replacement Blue Sky") ? <AuthGuardAr><SkyReplacementBlueSky /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> 
                                    <Route path="/ar/enhance/sky-replacement-dusk-shot" element={platform.enhance.includes('all') || platform.enhance.includes('Sky Replacement Dusk Shot') ? <AuthGuardAr><SkyReplacementDuskShot /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> 
                                    <Route path="/ar/enhance/remove-watermrk" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Watermark') ? <AuthGuardAr><RemoveWatermak /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/remove-watermrk-universal" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Watermark') ? <AuthGuardAr><RemoveWatermakUniversal /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/remove-duplicate" element={platform.enhance.includes('all')  || platform.enhance.includes('Remove Duplicates') ? <AuthGuardAr><RemoveDuplicate /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/add-watermrk" element={platform.enhance.includes('all') || platform.enhance.includes('Add Watermark') ? <AuthGuardAr><AddWatermak /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/remove-objects" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Objects Detect') ? <AuthGuardAr><RemoveObjects /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/remove-objects-manual" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Objects Manual') ? <AuthGuardAr><RemoveObjectsManual /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    {/* <Route path="/ar/enhance/defocus-bluriness" element={platform.enhance.includes('all') ? <AuthGuardAr><DefocusBluriness /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> */}
                                    {/* <Route path="/ar/enhance/improve-image-white-balance" element={platform.enhance.includes('all') ? <AuthGuardAr><ImproveBalance /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> */}
                                    <Route path="/ar/enhance/improve-image-contrast" element={platform.enhance.includes('all') || platform.enhance.includes('Improve Contrast') ? <AuthGuardAr><ImproveContrast /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    {/* <Route path="/ar/enhance/correct-image-rotation" element={platform.enhance.includes('all') ? <AuthGuardAr><CorrectImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> */}
                                    <Route path="/ar/enhance/blur-car-plates" element={platform.enhance.includes('all') || platform.enhance.includes('Blur Car Plates') ? <AuthGuardAr><BlurrCar /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/blur-people-faces" element={platform.enhance.includes('all') || platform.enhance.includes('Blur People Faces') ? <AuthGuardAr><BlurrPeople /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/unbox-image" element={platform.enhance.includes('all') || platform.enhance.includes('Unbox Image') ? <AuthGuardAr><UnboxImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/resize-image" element={platform.enhance.includes('all') || platform.enhance.includes('Resize Image') ? <AuthGuardAr><ResizeImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/compress-image" element={platform.enhance.includes('all') || platform.enhance.includes('Compress Image') ? <AuthGuardAr><CompressImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/add-QR-code-to-image" element={platform.enhance.includes('all') || platform.enhance.includes('Add QR Code to Image') ? <AuthGuardAr><AddQRCodeImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/add-QR-code-to-video" element={platform.enhance.includes('all') || platform.enhance.includes('Add QR Code to Video') ? <AuthGuardAr><AddQRCodeVideo /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/add-QR-code-to-listing" element={platform.enhance.includes('all') || platform.enhance.includes('Add QR Code to Listing') ? <AuthGuardAr><AddQRCodeListing /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/low-light-enhancement" element={platform.enhance.includes('all') || platform.enhance.includes('Low Light Enhancement') ? <AuthGuardAr><LowLightEnhancement /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/image-resize-to-webp" element={platform.enhance.includes('all') || platform.enhance.includes('Resize Image To Webp') ? <AuthGuardAr><ImageResizeToWebp /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                    <Route path="/ar/enhance/listing-booster" element={platform.enhance.includes('all') || platform.enhance.includes('Listing Booster') ? <AuthGuardAr><ListingBooster /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/enhance/remove-replace-PII" element={platform.enhance.includes('all') || platform.enhance.includes('Remove / Replace PII') ? <AuthGuardAr><RemoveReplacePII /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                </React.Fragment>
                            )
                        }
                        if (platform.delight) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/ar/delight" element={platform.delight.includes('all') ? <AuthGuardAr><DelightMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.understand) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/ar/understand/image-content" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><ImgContentMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImgComplianceMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><TextMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/document" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') || platform.understand.includes('Emirates ID - Content (UAE)') ? <AuthGuardAr><DocumentMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><AudioMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                    <Route path="/ar/understand/image-content/residential-type" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><ResidentialType /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/commercial-type" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><CommercialType /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/indoor-outdoor" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><Indoor /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/finished-unfinished-walls" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><UnfurnishedFurnished /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/commercial-residential-property" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><ResidProperty /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/property-view" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><PropertyView /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/building-facility" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><BuildingFacility /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/watermark-present" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><WatermarkPresent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-content/image-tagger" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuardAr><ImageTagger /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                    <Route path="/ar/understand/image-compliance/NSFW-content" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><NSFWContent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/people-present" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><PeoplePresent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/phone-number" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><PhoneNumber /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/image-rotated" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImageRotated /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/image-boxed" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImageBoxed /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/image-brightness-score" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImageBrightnessScore /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/image-quality-score" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImageQualityScore /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/image-ai-generated" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImageAIGenerated /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/image-compliance/image-computer-generated" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ImageComputerGenerated /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                    <Route path="/ar/understand/document/document-type" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all')  ? <AuthGuardAr><DocumentType /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/document/emirates-id" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') || platform.understand.includes('Emirates ID - Content (UAE)')  ? <AuthGuardAr><EmiratesContent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/document/passport-content" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all')  ? <AuthGuardAr><PassportContent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/document/title-deed" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all')|| platform.understand.includes('Title Deed - Content (UAE)')   ? <AuthGuardAr><TitleDeedContent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/document/driving-license-content" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all')  ? <AuthGuardAr><DocumentContent /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/document/OCR" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all')  ? <AuthGuardAr><OCR /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                    <Route path="/ar/understand/text/personaly-identifiable" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><PersonalyIdentifiable /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text/racial-speech" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><RacialSpeech /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text/NSFW-content" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><NSFWContentText /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    {/* <Route path="/ar/understand/text/hate-speech-racial" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><HateSpeechRacialText /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> */}
                                    <Route path="/ar/understand/text/sentiment-analysis" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><SentimentAnalysisText /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text/feedback-analysis" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><FeedbackAnalysisText /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text/tone" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><ToneText /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text/detect-convesration-predefined-topics" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><DetectConvesrationPredefinedTopics /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/text/detect-convesration-auto-identification" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><DetectConvesrationAutoIdentif /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                    <Route path="/ar/understand/audio/detect-convesration-topics-auto-identification" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><DetectConvesrationTopics /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/detect-convesration-topics-predefined-topics" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><AssignPredefinedTopics /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/sentiment-analysis" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><SentimentAnalysis /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/feedback-analysis" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><FeedbackAnalysis /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/tone" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><Tone /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/voice-features" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><VoiceFeatures /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/hate-speech-racial" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><HateSpeechRacial /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/understand/audio/transcribe-audio" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuardAr><TranscribeAudio /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.moderate) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/ar/moderate/overview" element={platform.moderate.includes('all') ? <AuthGuardAr><Overview /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/moderate/items" element={platform.moderate.includes('all') ? <AuthGuardAr><Items /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/moderate/rules" element={platform.moderate.includes('all') ? <AuthGuardAr><Rules /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/moderate/manual" element={platform.moderate.includes('all') || platform.moderate.includes('manual')? <AuthGuardAr><Manuals /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/moderate/duplicate" element={platform.moderate.includes('all') ? <AuthGuardAr><DuplicateSettings /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/moderate/:status" element={platform.moderate.includes('all') ? <AuthGuardAr><ItemsStatus /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/moderate/property/:id" element={platform.moderate.includes('all') ? <AuthGuardAr><ItemDetails /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.generate) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/ar/generate" element={platform.generate ? <AuthGuardAr><GenerateMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/ai_generation" element={platform.generate.includes('all') || platform.generate.includes('IA Listing Generation') ? <AuthGuardAr><AIGeneration /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/property-description" element={platform.generate.includes('all') || platform.generate.includes('Description Generation') ? <AuthGuardAr><PropertyDescription /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/property-title" element={platform.generate.includes('all') || platform.generate.includes('Title Generation') ? <AuthGuardAr><PropertyTitle /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/coraGPT" element={platform.generate.includes('all') || platform.generate.includes('CoraGPT') ? <AuthGuardAr> <CoraGPT /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/generate-lease-agreement" element={platform.generate.includes('all') || platform.generate.includes('Lease Generation') ? <AuthGuardAr> <GenerateLeaseAgreement /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/room-staging" element={platform.generate.includes('all') || platform.generate.includes('Room Staging') ? <AuthGuardAr><RoomStaging /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/room-remodeling" element={platform.generate.includes('all') || platform.generate.includes('Room Remodeling') ? <AuthGuardAr><RoomModeling /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/room-image-generation" element={platform.generate.includes('all') || platform.generate.includes('Room Generation') ? <AuthGuardAr><RoomImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/generate-QR-code" element={platform.generate.includes('all') || platform.generate.includes('QR code Generation') ? <AuthGuardAr><GenerateQRCode /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    {/* <Route path="/ar/generate/listing-video/:template/:video" element={platform.generate.includes('all') || platform.generate.includes('Listing Video') ? <AuthGuardAr><PhotoCollage /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> */}
                                    <Route path="/ar/generate/agent-profile-video-reel" element={platform.generate.includes('all') || platform.generate.includes('Agent Video') ? <AuthGuardAr><AgentProfileNew /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/agent-profile-video-post" element={platform.generate.includes('all') || platform.generate.includes('Agent Video') ? <AuthGuardAr><AgentProfileNew /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    
                                    {/* <Route path="/ar/generate/social-media" element={platform.generate.includes('all') || platform.generate.includes('Socail Media') ? <AuthGuardAr><SocialMedia /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> */}
                                    <Route path="/ar/generate/agent-profile-video" element={platform.generate.includes('all') || platform.generate.includes('Agent Video') ? <AuthGuardAr><AgentProfile /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/template" element={platform.generate ? <AuthGuardAr><Template /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/listing-video" element={platform.generate.includes('all') || platform.generate.includes('Listing Video') ? <AuthGuardAr><ListingVideo /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/floor-plan-generation" element={platform.generate.includes('all') || platform.generate.includes('3D Floor Plan Generation') ? <AuthGuardAr><FloorPlan3D /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/generate/style-transfer" element={platform.generate.includes('all') || platform.generate.includes('Room Remodeling') ? <AuthGuardAr><StyleTransfer /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.agent_plus) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/ar/agent-plus" element={platform.agent_plus ? <AuthGuardAr><AgentPlusMain /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/generate-QR-code" element={platform.agent_plus  ? <AuthGuardAr><GenerateQRCode /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/generate-description" element={platform.agent_plus.includes('Description Generation') ? <AuthGuardAr><PropertyDescription /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/listing-video" element={platform.agent_plus.includes('Listing Video') ? <AuthGuardAr><ListingVideo /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/generate-title" element={platform.agent_plus.includes('Title Generation') ? <AuthGuardAr><PropertyTitle /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/listing-booster" element={platform.agent_plus.includes('Listing Booster') ? <AuthGuardAr><ListingBooster /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/image-super-resolution" element={platform.agent_plus.includes('Image Super Resolution') ? <AuthGuardAr><ImageSuperResolution /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/remove-watermark" element={platform.agent_plus.includes('Remove Watermark') ? <AuthGuardAr><RemoveWatermak /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/room-staging" element={platform.agent_plus.includes('Room Staging') ? <AuthGuardAr><RoomStaging /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/room-remodeling" element={platform.agent_plus.includes('Room Remodeling') ? <AuthGuardAr><RoomModeling /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/generate-lease-agreement" element={platform.agent_plus ? <AuthGuardAr> <GenerateLeaseAgreement /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/ai_generation" element={platform.agent_plus ? <AuthGuardAr><AIGeneration /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/low-light-enhancement" element={platform.agent_plus ? <AuthGuardAr><LowLightEnhancement /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/sky-replacement-blue-sky" element={platform.agent_plus ? <AuthGuardAr><SkyReplacementBlueSky /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> 
                                    <Route path="/ar/agent-plus/sky-replacement-dusk-shot" element={platform.agent_plus ? <AuthGuardAr><SkyReplacementDuskShot /></AuthGuardAr> : <Navigate to="/ar/properties" />} /> 
                                    <Route path="/ar/agent-plus/resize-image" element={platform.agent_plus  ? <AuthGuardAr><ResizeImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />
                                    <Route path="/ar/agent-plus/compress-image" element={platform.agent_plus ? <AuthGuardAr><CompressImage /></AuthGuardAr> : <Navigate to="/ar/properties" />} />

                                </React.Fragment>)
                        }
                    })
                    : <></>}
               
                <Route path="/ar/*" element={<AuthGuardAr><Navigate to="/ar/login" /></AuthGuardAr>} />
                <Route path="/ar/profile" element={<AuthGuardAr><Profile /></AuthGuardAr>} />
                <Route path="/ar/properties" element={<AuthGuardAr><Properties /></AuthGuardAr>} />
                <Route path="/ar/properties/:id" element={<AuthGuardAr><PropertyDetails /></AuthGuardAr>} />
                <Route path="/ar/settings" element={<AuthGuardAr><Settings /></AuthGuardAr>} />
            </Route>
        </Routes>

    );
};

export default PublicRouterAr;