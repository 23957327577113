import React from 'react';
import Login from '../Components/login';
import Account from '../Components/account';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Layout from '../Components/Layout/layout';
import Home from '../Components/home';
import Properties from '../Components/Properties/properties';
import PropertyDetails from '../Components/Properties/property-details';
import GenerateMain from '../Components/Generate/generate-main';
import PropertyDescription from '../Components/Generate/text/property-description';
import PropertyTitle from '../Components/Generate/text/property-title';
import CoraGPT from '../Components/Generate/text/coraGPT';
import RoomImage from '../Components/Generate/image/room-image';
import RoomStaging from '../Components/Generate/image/room-staging.tsx';
import AuthGuard from '../Services/AuthGuard';
import RoomModeling from '../Components/Generate/image/roomodeling';
import ItemDetails from '../Components/Moderate/item-details';
import ResidentialType from '../Components/Understand/ImgContent/residential-type';
import CommercialType from '../Components/Understand/ImgContent/commercial-type';
import Indoor from '../Components/Understand/ImgContent/indoor';
import ResidProperty from '../Components/Understand/ImgContent/resid-property';
import PropertyView from '../Components/Understand/ImgContent/property-view';
import BuildingFacility from '../Components/Understand/ImgContent/building-facility';
import WatermarkPresent from '../Components/Understand/ImgContent/watermark-present';
import NSFWContent from '../Components/Understand/ImgCompliance/NSFW-content';
import PeoplePresent from '../Components/Understand/ImgCompliance/people-present';
import PhoneNumber from '../Components/Understand/ImgCompliance/phone-number';
import ImageRotated from '../Components/Understand/ImgCompliance/image-rotated';
import ImageBoxed from '../Components/Understand/ImgCompliance/image-boxed';
import DocumentType from '../Components/Understand/Document/document-type';
import EmiratesContent from '../Components/Understand/Document/emirates- content';
import PassportContent from '../Components/Understand/Document/passport-content';
import TitleDeedContent from '../Components/Understand/Document/title-deed-content';
import DocumentContent from '../Components/Understand/Document/document-content';
import PersonalyIdentifiable from '../Components/Understand/Text/personaly-identifiable';
import RacialSpeech from '../Components/Understand/Text/racial-speech';
import NSFWContentText from '../Components/Understand/Text/NSFW-content-text';
import UnfurnishedFurnished from '../Components/Understand/ImgContent/unfurnished-furnished';
import DelightMain from '../Components/Delight/delight-main';
import EnhanceMain from '../Components/Enhance/enhance-main';
import ImageSuperResolution from '../Components/Enhance/image/image-super-resolution';
import ListingBooster from '../Components/Enhance/listing-booster/listing-booster';
import RemoveReplacePII from '../Components/Enhance/text/remove-replace-PII';
import RemoveImageNoise from '../Components/Enhance/image/remove-image-noise';
import RemoveWatermak from '../Components/Enhance/image/remove-watermark';
import ImproveContrast from '../Components/Enhance/image/improve-image-contrast';
import BlurrCar from '../Components/Enhance/image/blurr-car-plates';
import BlurrPeople from '../Components/Enhance/image/blurr-people-faces';
import UnboxImage from '../Components/Enhance/image/unbox-image';
import DetectConvesrationTopics from '../Components/Understand/Audio/detect-convesration-topics';
import AssignPredefinedTopics from '../Components/Understand/Audio/assign-predefined-topics';
import SentimentAnalysis from '../Components/Understand/Audio/sentiment-analysis';
import FeedbackAnalysis from '../Components/Understand/Audio/feedback-analysis';
import Tone from '../Components/Understand/Audio/tone';
import VoiceFeatures from '../Components/Understand/Audio/voice-features';
import HateSpeechRacial from '../Components/Understand/Audio/hate-speech-racial';
import TranscribeAudio from '../Components/Understand/Audio/transcribe-audio';
import AgentProfile from '../Components/Generate/video/Agent Profile';
import ImageBrightnessScore from '../Components/Understand/ImgCompliance/image-brightness-score';
import ImageQualityScore from '../Components/Understand/ImgCompliance/image-quality-score';
import Profile from '../Components/Settings/profile';
import Invitation from '../Components/Users/invitation';
import Settings from '../Components/Settings/settings';
import AgentPlusMain from '../Components/AgentPlus/agent-plus-main';
import PublicPagesGuard from '../Services/PublicPagesGuard';
import SentimentAnalysisText from '../Components/Understand/Text/sentiment-analysis-text';
import FeedbackAnalysisText from '../Components/Understand/Text/feedback-analysis-text';
import ToneText from '../Components/Understand/Text/tone-text';
import DetectConvesrationPredefinedTopics from '../Components/Understand/Text/detect-convesration-predefined-topics';
import DetectConvesrationAutoIdentif from '../Components/Understand/Text/detect-convesration-auto-identification';
import GenerateLeaseAgreement from '../Components/Generate/GenerateLeaseAgreement/generate-lease-agreement';
import ItemsStatus from '../Components/Moderate/items-status';
import OCR from '../Components/Understand/Document/Ocr';
import ResizeImage from '../Components/Enhance/image/resize-image';
import ImgComplianceMain from '../Components/Understand/ImgCompliance/image-compliance-main';
import ImgContentMain from '../Components/Understand/ImgContent/img-content-main';
import TextMain from '../Components/Understand/Text/text-main';
import DocumentMain from '../Components/Understand/Document/DocumentMain';
import AudioMain from '../Components/Understand/Audio/audio-main';
import Overview from '../Components/Moderate/overview';
import Items from '../Components/Moderate/items';
import Rules from '../Components/Moderate/rules';
import Manuals from '../Components/Moderate/manuals';
import DuplicateSettings from '../Components/Moderate/duplicate-settings';
import AIGeneration from '../Components/Generate/listing/AIGeneration';
import RemoveDuplicate from '../Components/Enhance/image/remove-duplicate';
import AddWatermak from '../Components/Enhance/image/add-watermark';
import CompressImage from '../Components/Enhance/image/compress-image';
import ImageAIGenerated from '../Components/Understand/ImgCompliance/image-ai-generated';
import ImageComputerGenerated from '../Components/Understand/ImgCompliance/image-computer-generated';
import GenerateQRCode from '../Components/Generate/image/generate-QR-code';
import ImageTagger from '../Components/Understand/ImgContent/image-tagger';
import RemoveWatermakUniversal from '../Components/Enhance/image/remove-watermrk-universal';
import AddQRCodeImage from '../Components/Enhance/image/add-QR-code-image';
import AddQRCodeVideo from '../Components/Enhance/image/add-QR-code-video';
import AddQRCodeListing from '../Components/Enhance/image/add-QR-code-listing';
import LowLightEnhancement from '../Components/Enhance/image/low-light-enhancement';
import ListingVideo from '../Components/Generate/video/listing-video';
import ImageResizeToWebp from '../Components/Enhance/image/image-resize-to-webp';
import FloorPlan3D from '../Components/Generate/listing/floor-plan-3D';
import RemoveObjects from '../Components/Enhance/image/remove-objects';
import RemoveObjectsManual from '../Components/Enhance/image/remove-objects-manual';
import AgentProfileReel from '../Components/Generate/video/agent-profile-reel';
import SkyReplacementBlueSky from '../Components/Enhance/image/sky-replacement-blue-sky';
import SkyReplacementDuskShot from '../Components/Enhance/image/sky-replacement-dusk-shot';
import AgentProfileNew1 from '../Components/Generate/video/Agent video/agent-profile-new1';
import AgentProfileNew2 from '../Components/Generate/video/Agent video/agent-profile-new2';
import ListPost from '../Components/Generate/Post/list-post';
import Post from '../Components/Generate/Post/Post';
import StyleTransfer from '../Components/Generate/image/generate-style-transfer';


const PublicRouterEn: React.FunctionComponent<{}> = (props) => {

    const storedPlatforms: any = JSON.parse(localStorage.getItem('get-platforms') || '[]');
    const location = useLocation();

    const isArPath = location.pathname.startsWith('/ar/');

    return (

        <Routes>
            <Route path="/account" element={<Account />} />
            <Route path="/acceptinvit/:id" element={<Invitation />} />
            <Route path="/login" element={<PublicPagesGuard><Login /></PublicPagesGuard>} />
            <Route path="" element={<PublicPagesGuard><Login /></PublicPagesGuard>} />
            {isArPath ? <></> : <Route path="/" element={<AuthGuard><Layout /></AuthGuard>} >

                {storedPlatforms ?
                    storedPlatforms.map((platform: any, index: any) => {
                        if (platform.home) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/home" element={platform.home ? <AuthGuard><Home /></AuthGuard> : <Navigate to="/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.enhance) { 
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/enhance" element={platform.enhance ? <AuthGuard><EnhanceMain /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/image-super-resolution" element={platform.enhance.includes('all') || platform.enhance.includes('Image Super Resolution') ? <AuthGuard><ImageSuperResolution /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-image-noise" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Image Noise') ? <AuthGuard><RemoveImageNoise /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/sky-replacement-blue-sky" element={platform.enhance.includes('all') || platform.enhance.includes('Sky Replacement Blue Sky') ? <AuthGuard><SkyReplacementBlueSky /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/sky-replacement-dusk-shot" element={platform.enhance.includes('all') || platform.enhance.includes('Sky Replacement Dusk Shot') ? <AuthGuard><SkyReplacementDuskShot /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-watermrk" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Watermark') ? <AuthGuard><RemoveWatermak /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-watermrk-universal" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Watermark') ? <AuthGuard><RemoveWatermakUniversal /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-duplicate" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Duplicates') ? <AuthGuard><RemoveDuplicate /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/add-watermrk" element={platform.enhance.includes('all') || platform.enhance.includes('Add Watermark') ? <AuthGuard><AddWatermak /></AuthGuard> : <Navigate to="/properties" />} />
                                    {/* <Route path="/enhance/defocus-bluriness" element={platform.enhance.includes('all') ? <AuthGuard><DefocusBluriness /></AuthGuard> : <Navigate to="/properties" />} /> */}
                                    {/* <Route path="/enhance/improve-image-white-balance" element={platform.enhance.includes('all') ? <AuthGuard><ImproveBalance /></AuthGuard> : <Navigate to="/properties" />} /> */}
                                    <Route path="/enhance/improve-image-contrast" element={platform.enhance.includes('all') || platform.enhance.includes('Improve Contrast') ? <AuthGuard><ImproveContrast /></AuthGuard> : <Navigate to="/properties" />} />
                                    {/* <Route path="/enhance/correct-image-rotation" element={platform.enhance.includes('all') ? <AuthGuard><CorrectImage /></AuthGuard> : <Navigate to="/properties" />} /> */}
                                    <Route path="/enhance/blur-car-plates" element={platform.enhance.includes('all') || platform.enhance.includes('Blur Car Plates') ? <AuthGuard><BlurrCar /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/blur-people-faces" element={platform.enhance.includes('all') || platform.enhance.includes('Blur People Faces') ? <AuthGuard><BlurrPeople /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-objects" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Objects Detect') ? <AuthGuard><RemoveObjects /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-objects-manual" element={platform.enhance.includes('all') || platform.enhance.includes('Remove Objects Manual') ? <AuthGuard><RemoveObjectsManual /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/unbox-image" element={platform.enhance.includes('all') || platform.enhance.includes('Unbox Image') ? <AuthGuard><UnboxImage /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/resize-image" element={platform.enhance.includes('all') || platform.enhance.includes('Resize Image') ? <AuthGuard><ResizeImage /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/compress-image" element={platform.enhance.includes('all') || platform.enhance.includes('Compress Image') ? <AuthGuard><CompressImage /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/add-QR-code-to-image" element={platform.enhance.includes('all') || platform.enhance.includes('Add QR Code to Image') ? <AuthGuard><AddQRCodeImage /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/add-QR-code-to-video" element={platform.enhance.includes('all') || platform.enhance.includes('Add QR Code to Video') ? <AuthGuard><AddQRCodeVideo /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/add-QR-code-to-listing" element={platform.enhance.includes('all') || platform.enhance.includes('Add QR Code to Listing') ? <AuthGuard><AddQRCodeListing /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/low-light-enhancement" element={platform.enhance.includes('all') || platform.enhance.includes('Low Light Enhancement') ? <AuthGuard><LowLightEnhancement /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/image-resize-to-webp" element={platform.enhance.includes('all') || platform.enhance.includes('Resize Image To Webp') ? <AuthGuard><ImageResizeToWebp /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/listing-booster" element={platform.enhance.includes('all') || platform.enhance.includes('Listing Booster') ? <AuthGuard><ListingBooster /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/enhance/remove-replace-PII" element={platform.enhance.includes('all') || platform.enhance.includes('Remove / Replace PII') ? <AuthGuard><RemoveReplacePII /></AuthGuard> : <Navigate to="/properties" />} />
                                </React.Fragment>
                            )
                        }
                        if (platform.delight) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/delight" element={platform.delight.includes('all') ? <AuthGuard><DelightMain /></AuthGuard> : <Navigate to="/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.understand) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/understand/image-content" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><ImgContentMain /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImgComplianceMain /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><TextMain /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/document" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') || platform.understand.includes('Emirates ID - Content (UAE)') ? <AuthGuard><DocumentMain /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><AudioMain /></AuthGuard> : <Navigate to="/properties" />} />

                                    <Route path="/understand/image-content/residential-type" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><ResidentialType /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/commercial-type" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><CommercialType /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/indoor-outdoor" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><Indoor /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/finished-unfinished-walls" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><UnfurnishedFurnished /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/commercial-residential-property" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><ResidProperty /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/property-view" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><PropertyView /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/building-facility" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><BuildingFacility /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/watermark-present" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><WatermarkPresent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-content/image-tagger" element={platform.understand.includes('Image Content') || platform.understand.includes('all') ? <AuthGuard><ImageTagger /></AuthGuard> : <Navigate to="/properties" />} />

                                    <Route path="/understand/image-compliance/NSFW-content" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><NSFWContent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/people-present" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><PeoplePresent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/phone-number" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><PhoneNumber /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/image-rotated" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImageRotated /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/image-boxed" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImageBoxed /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/image-brightness-score" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImageBrightnessScore /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/image-quality-score" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImageQualityScore /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/image-ai-generated" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImageAIGenerated /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/image-compliance/image-computer-generated" element={platform.understand.includes('Image Compliance') || platform.understand.includes('all') ? <AuthGuard><ImageComputerGenerated /></AuthGuard> : <Navigate to="/properties" />} />

                                    <Route path="/understand/document/document-type" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><DocumentType /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/document/emirates-id" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') || platform.understand.includes('Emirates ID - Content (UAE)') ? <AuthGuard><EmiratesContent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/document/passport-content" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><PassportContent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/document/title-deed" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') || platform.understand.includes('Title Deed - Content (UAE)') ? <AuthGuard><TitleDeedContent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/document/driving-license-content" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><DocumentContent /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/document/OCR" element={platform.understand.includes('Document Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><OCR /></AuthGuard> : <Navigate to="/properties" />} />

                                    <Route path="/understand/text/personaly-identifiable" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><PersonalyIdentifiable /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text/racial-speech" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><RacialSpeech /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text/NSFW-content" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><NSFWContentText /></AuthGuard> : <Navigate to="/properties" />} />
                                    {/* <Route path="/understand/text/hate-speech-racial" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><HateSpeechRacialText /></AuthGuard> : <Navigate to="/properties" />} /> */}
                                    <Route path="/understand/text/sentiment-analysis" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><SentimentAnalysisText /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text/feedback-analysis" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><FeedbackAnalysisText /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text/tone" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><ToneText /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text/detect-convesration-predefined-topics" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><DetectConvesrationPredefinedTopics /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/text/detect-convesration-auto-identification" element={platform.understand.includes('Text Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><DetectConvesrationAutoIdentif /></AuthGuard> : <Navigate to="/properties" />} />

                                    <Route path="/understand/audio/detect-convesration-topics-auto-identification" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><DetectConvesrationTopics /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/detect-convesration-topics-predefined-topics" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><AssignPredefinedTopics /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/sentiment-analysis" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><SentimentAnalysis /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/feedback-analysis" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><FeedbackAnalysis /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/tone" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><Tone /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/voice-features" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><VoiceFeatures /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/hate-speech-racial" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><HateSpeechRacial /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/understand/audio/transcribe-audio" element={platform.understand.includes('Audio Content and Compliance') || platform.understand.includes('all') ? <AuthGuard><TranscribeAudio /></AuthGuard> : <Navigate to="/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.moderate) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/moderate/overview" element={platform.moderate.includes('all') ? <AuthGuard><Overview /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/moderate/items" element={platform.moderate.includes('all') ? <AuthGuard><Items /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/moderate/rules" element={platform.moderate.includes('all') ? <AuthGuard><Rules /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/moderate/manual" element={platform.moderate.includes('all') || platform.moderate.includes('manual') ? <AuthGuard><Manuals /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/moderate/duplicate" element={platform.moderate.includes('all') ? <AuthGuard><DuplicateSettings /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/moderate/:status" element={platform.moderate.includes('all') ? <AuthGuard><ItemsStatus /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/moderate/property/:id" element={platform.moderate.includes('all') ? <AuthGuard><ItemDetails /></AuthGuard> : <Navigate to="/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.generate) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/generate" element={platform.generate ? <AuthGuard><GenerateMain /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/ai_generation" element={platform.generate.includes('all') || platform.generate.includes('IA Listing Generation') ? <AuthGuard><AIGeneration /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/property-description" element={platform.generate.includes('all') || platform.generate.includes('Description Generation') ? <AuthGuard><PropertyDescription /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/property-title" element={platform.generate.includes('all') || platform.generate.includes('Title Generation') ? <AuthGuard><PropertyTitle /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/coraGPT" element={platform.generate.includes('all') || platform.generate.includes('CoraGPT') ? <AuthGuard> <CoraGPT /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/generate-lease-agreement" element={platform.generate.includes('all') || platform.generate.includes('Lease Generation') ? <AuthGuard> <GenerateLeaseAgreement /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/room-staging" element={platform.generate.includes('all') || platform.generate.includes('Room Staging') ? <AuthGuard><RoomStaging /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/room-remodeling" element={platform.generate.includes('all') || platform.generate.includes('Room Remodeling') ? <AuthGuard><RoomModeling /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/room-image-generation" element={platform.generate.includes('all') || platform.generate.includes('Room Generation') ? <AuthGuard><RoomImage /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/generate-QR-code" element={platform.generate.includes('all') || platform.generate.includes('QR code Generation') ? <AuthGuard><GenerateQRCode /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/agent-profile-video" element={platform.generate.includes('all') || platform.generate.includes('Agent Video') ? <AuthGuard><AgentProfile /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/agent-profile-video-reel" element={platform.generate.includes('all') || platform.generate.includes('Agent Video') ? <AuthGuard><AgentProfileReel /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/agent-profile-video-post" element={platform.generate.includes('all') || platform.generate.includes('Agent Video') ? <AuthGuard><AgentProfileReel /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/listing-video" element={platform.generate.includes('all') || platform.generate.includes('Listing Video') ? <AuthGuard><ListingVideo /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/floor-plan-generation" element={platform.generate.includes('all') || platform.generate.includes('3D Floor Plan Generation') ? <AuthGuard><FloorPlan3D /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/list-post" element={platform.generate.includes('all') || platform.generate.includes('Generate Post') ? <AuthGuard><ListPost /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/generate-post" element={platform.generate.includes('all') || platform.generate.includes('Generate Post') ? <AuthGuard><Post/></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/generate/style-transfer" element={platform.generate.includes('all') || platform.generate.includes('Room Remodeling') ? <AuthGuard><StyleTransfer /></AuthGuard> : <Navigate to="/properties" />} />
                                </React.Fragment>)
                        }
                        if (platform.agent_plus) {
                            return (
                                <React.Fragment key={index}>
                                    <Route path="/agent-plus" element={platform.agent_plus ? <AuthGuard><AgentPlusMain /></AuthGuard> : <AuthGuard><Properties /></AuthGuard>} />
                                    <Route path="/agent-plus/generate-QR-code" element={platform.agent_plus ? <AuthGuard><GenerateQRCode /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/generate-description" element={platform.agent_plus.includes('Description Generation') ? <AuthGuard><PropertyDescription /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/listing-video" element={platform.agent_plus.includes('Listing Video') ? <AuthGuard><ListingVideo /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/generate-title" element={platform.agent_plus.includes('Title Generation') ? <AuthGuard><PropertyTitle /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/listing-booster" element={platform.agent_plus.includes('Listing Booster') ? <AuthGuard><ListingBooster /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/image-super-resolution" element={platform.agent_plus.includes('Image Super Resolution') ? <AuthGuard><ImageSuperResolution /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/remove-watermark" element={platform.agent_plus.includes('Remove Watermark') ? <AuthGuard><RemoveWatermak /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/room-staging" element={platform.agent_plus.includes('Room Staging') ? <AuthGuard><RoomStaging /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/room-remodeling" element={platform.agent_plus.includes('Room Remodeling') ? <AuthGuard><RoomModeling /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/generate-lease-agreement" element={platform.agent_plus ? <AuthGuard> <GenerateLeaseAgreement /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/ai_generation" element={platform.agent_plus ? <AuthGuard><AIGeneration /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/low-light-enhancement" element={platform.agent_plus ? <AuthGuard><LowLightEnhancement /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/sky-replacement-blue-sky" element={platform.agent_plus ? <AuthGuard><SkyReplacementBlueSky /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/sky-replacement-dusk-shot" element={platform.agent_plus ? <AuthGuard><SkyReplacementDuskShot /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/resize-image" element={platform.agent_plus  ? <AuthGuard><ResizeImage /></AuthGuard> : <Navigate to="/properties" />} />
                                    <Route path="/agent-plus/compress-image" element={platform.agent_plus ? <AuthGuard><CompressImage /></AuthGuard> : <Navigate to="/properties" />} />

                                </React.Fragment>)
                        }
                    })
                    : <></>
                }
                <Route path="*" element={<AuthGuard><Navigate to="/login" /></AuthGuard>} />
                <Route path="/profile" element={<AuthGuard><Profile /></AuthGuard>} />

                <Route path="/properties" element={<AuthGuard><Properties /></AuthGuard>} />
                <Route path="/agent-profile-new" element={<AuthGuard><AgentProfileNew1 /></AuthGuard>} />
                <Route path="/agent-profile/generate-video" element={<AuthGuard><AgentProfileNew2 /></AuthGuard>} />
                <Route path="/properties/:id" element={<AuthGuard><PropertyDetails /></AuthGuard>} />
                <Route path="/settings" element={<AuthGuard><Settings /></AuthGuard>} />

            </Route>
            }
        </Routes>

    );
};

export default PublicRouterEn;