import axios from "axios";

const Url = process.env.REACT_APP_URl;

// image

export const EnhanceImageResolution = async (Obj: any) => {
  const apiUrl = Url + "/image-super-resolution";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const EnhanceImageResolution2D = async (Obj: any) => {
  const apiUrl = Url + "/image-super-resolution_2d";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const SkyReplacementBlueSkyService = async (Obj: any) => {
  const apiUrl = Url + "/sky_enhancement";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const SkyReplacementDuskSkyService = async (Obj: any) => {
  const apiUrl = Url + "/sky_replace_dusk_sky";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const RemoveObjectsService = async (obj: any) => {
  const apiUrl = Url + "/Remove_Object";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const RemoveObjectsManualService = async (obj: any) => {
  const apiUrl = Url + "/remove-object-manualy";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const LowLightEnhancementService = async (Obj: any) => {
  const apiUrl = Url + "/low-light-enhancement";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const RemoveImageNoiseService = async (Obj: any) => {
  const apiUrl = Url + "/remove-image-noise";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const RemoveBluriness = async (Obj: any) => {
  const apiUrl = Url + "/remove-image-bluriness";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const RemoveDuplicateService = async (Obj: any) => {
  const apiUrl = Url + "/remove_duplicates";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ResizeImageService = async (Obj: any) => {
  const apiUrl = Url + "/correct-size";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const DefocusBlurinessService = async (Obj: any) => {
  const apiUrl = Url + "/defocus-bluriness";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ImproveBalanceService = async (Obj: any) => {
  const apiUrl = Url + "/improve-image-white-balance";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const ImproveContrastService = async (Obj: any) => {
  const apiUrl = Url + "/improve-image-contrast";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const CompressImageService = async (Obj: any) => {
  const apiUrl = Url + "/compress-image";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ImageResizeToWebpService = async (Obj: any) => {
  const apiUrl = Url + "/image-resize-to-webp";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}

export const ImproveBlurrinessService = async (Obj: any) => {
  const apiUrl = Url + "/improve-blurriness";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const RemoveWatermarkService = async (Obj: any) => {
  const apiUrl = Url + "/remove-watermark";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const RemoveWatermarkUniService = async (Obj: any) => {
  const apiUrl = Url + "/remove_watermark_universal";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const AddWatermarkService = async (Obj: any) => {
  const apiUrl = Url + "/add_watermark";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const AddQRCodeImgService = async (Obj: any) => {
  const apiUrl = Url + "/add_watermark";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const AddQRCodeListingService = async (Obj: any) => {
  const apiUrl = Url + "/add_QR_tolisting";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const GetQRCodeService = async (id: any) => {
  const apiUrl = Url + "/propertytarkhis";
  const response = await axios.get(`${apiUrl}/${id}`, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const AddQRCodeVideoService = async (Obj: any) => {
  const apiUrl = Url + "/add_watermark_tovideo";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const CorrectImageService = async (Obj: any) => {
  const apiUrl = Url + "/correct-image-rotation";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const BlurrCarService = async (Obj: any) => {
  const apiUrl = Url + "/blurr-car-plates";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const BlurrPeopleService = async (Obj: any) => {
  const apiUrl = Url + "/blurr-people-faces";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
export const UnboxImageService = async (Obj: any) => {
  const apiUrl = Url + "/unbox-image";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
//booster
export const ListingBoosterService = async (Obj: any) => {
  const apiUrl = Url + "/boosting_listing";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
// text
export const RemoveReplaceService = async (Obj: any) => {
  const apiUrl = Url + "/pii_removal";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  localStorage.setItem('curl', apiUrl);
  return response.data;
}
// replace images


export const ReplaceImgEnhance = async (Obj: any) => {
  const apiUrl = Url + "/replaceimage";
  const response = await axios.post(apiUrl, Obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}
